.infinitescroll {
	overflow-y: auto;

	.infinitescroll-end {
		clear: both;
		font-style: italic;
		padding: 6px;
		text-align: center;
	}

	.infinitescroll-load {
		clear: both;
		padding: 6px;

		button {
			padding: 5px 12px;
			width: 100%;
		}

		.loader {
			font-size: 32px;
			height: 32px;
			margin: 0 auto;
			width: 32px;
		}
	}
}