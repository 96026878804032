.fuelux {

	// Core variables, mixins, and icons
	@import "variables.less";	// Modify this for custom colors, font-sizes, etc
	@import "mixins.less";
	@import "icons.less";

	// Fuel UX controls
	@import "checkbox.less";
	@import "combobox.less";
	@import "datepicker.less";
	@import "infinite-scroll.less";
	@import "intelligent-dropdown.less";
	@import "loader.less";
	@import "misc.less";
	@import "pillbox.less";
	@import "placard.less";
	@import "radio.less";
	@import "repeater.less";
	@import "repeater-list.less";
	@import "repeater-thumbnail.less";
	@import "spinbox.less";
    @import "scheduler.less";
	@import "search.less";
	@import "selectlist.less";
	@import "tree.less";
	@import "wizard.less";

}
