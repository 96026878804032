.repeater-thumbnail {
	border: 1px solid #ddd;
	color: #666;
	float: left;
	font-size: 12px;
	height: 130px;
	line-height: 36px;
	margin: 10px 12px;
	text-align: center;
	width: 100px;

	-webkit-box-shadow: 0 1px 4px 0 #ddd;
	box-shadow: 0 1px 4px 0 #ddd;

	img {
		display: block;
		margin-top: 12px;
	}

	&-cont {
		height: 100%;
		overflow-y: auto;
		padding: 10px;
		width: 100%;

		div.empty {
			font-style: italic;
			padding: 20px 10px;
			text-align: center;
		}
	}
}