.wizard {

	.clearfix;

	border: 1px solid @navbarBorder;
	border-radius: @baseBorderRadius;
	box-shadow: 0 1px 4px rgba(0,0,0,.065);
	background-color: @tableBackgroundAccent;
	position: relative;
	min-height: 48px;
	overflow: hidden;

	.steps {
		list-style: none outside none;
		padding: 0;
		margin: 0;
		width: 4000px;

		&.previous-disabled {
			li {
				&.complete {
					cursor: default;

					&:hover {
						background: #f3f4f5;
						color: @successText;
						cursor: default;

						.chevron:before {
							border-left-color: #f3f4f5;
						}
					}
				}
			}
		}
		
		li {
			float: left;
			margin: 0;
			padding: 0 20px 0 30px;
			height: 46px;
			line-height: 46px;
			position: relative;
			background: #ededed;
			color: @grayLight;
			font-size: 16px;
			cursor: not-allowed;

			.chevron {
				border: 24px solid transparent;
				border-left: 14px solid @navbarBorder;
				border-right: 0;
				display: block;
				position: absolute;
				right: -14px;
				top: 0;
				z-index: 1;
			}

			.chevron:before {
				border: 24px solid transparent;
				border-left: 14px solid #ededed;
				border-right: 0;
				content: "";
				display: block;
				position: absolute;
				right: 1px;
				top: -24px;
			}

			&.complete {
				background: #f3f4f5;
				color: @successText;

				&:hover {
					background: #e7eff8;
					cursor: pointer;

					.chevron:before {
						border-left: 14px solid #e7eff8;
					}
				}

				.chevron:before {
					border-left: 14px solid #f3f4f5;
				}
			}
			&.active {
				background: #f1f6fc;
				color: @infoText;
				cursor: default;

				.chevron:before {
					border-left: 14px solid #f1f6fc;
				}

				.badge {
					background-color: @infoText;
				}
			}

			.badge {
				margin-right: 8px;
			}

			.badge-success {
				background-color: @successText;
			}
		}

		li:first-child {
			border-radius: 4px 0 0 4px;
			padding-left: 20px;
		}
	}

	.actions {
		z-index: 1000;
		position: absolute;
		right: 0;
		line-height: 46px;
		float: right;
		padding-left: 15px;
		padding-right: 15px;
		vertical-align: middle;
		background-color: #e5e5e5;
		border-left: 1px solid @navbarBorder;

		a {
			line-height: 45px;
			font-size: 12px;
			margin-right: 8px;
		}

		.btn-prev {
			&[disabled] {
				cursor: not-allowed;
			}

			span {
				margin-right: 5px;
			}
		}

		.btn-next {
			&[disabled] {
				cursor: not-allowed;
			}

			span {
				margin-left: 5px;
			}
		}
	}

	.step-content {
		border-top: 1px solid #D4D4D4;
		padding: 10px;
		float: left;
		width: 100%;

		.step-pane {
			display: none;
		}

		.active {
			display: block;

			.btn-group {
				.active {
					display: inline-block;
				}
			}
		}
	}

	// when complete
	&.complete {
			.actions .glyphicon-arrow-right:before {
				display: none;
			}
			.actions .glyphicon-arrow-right {
				margin-left: 0;
			}
		}
}